"use strict";

$(document).ready(function () {
  // ADD SAFARI CLASS TO BODY
  if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    document.body.className += " safari";
  }

  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    var ieV = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    document.querySelector('body').className += ' IE';
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    var ieV = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    document.querySelector('body').className += ' IE';
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // IE 12 (aka Edge) => return version number
    var ieV = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    document.querySelector('body').className += ' IE';
  }

  var enterShowButtons = document.querySelectorAll(".enter-show-cta");

  if (enterShowButtons !== null) {
    for (var i = 0; i < enterShowButtons.length; i++) {
      enterShowButtons[i].addEventListener("click", function (e) {
        e.preventDefault();
        $('html, body').animate({
          scrollTop: $('[data-section-index=3]').offset().top - 60
        }, 500);
      });
    }
  } // SLIDE FUNCTIONS


  var slideUp = function slideUp(target) {
    var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(function () {
      target.style.display = 'none';
      target.style.removeProperty('height');
      target.style.removeProperty('padding-top');
      target.style.removeProperty('padding-bottom');
      target.style.removeProperty('margin-top');
      target.style.removeProperty('margin-bottom');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property'); //alert("!");
    }, duration);
  };

  var slideDown = function slideDown(target) {
    var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
    target.style.removeProperty('display');
    var display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    var height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(function () {
      target.style.removeProperty('height');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
    }, duration);
  };

  var slideToggle = function slideToggle(target) {
    var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;

    if (window.getComputedStyle(target).display === 'none') {
      return slideDown(target, duration);
    } else {
      return slideUp(target, duration);
    }
  };

  // SMOOTH ANCHOR SCROLL LINKS

  $(document).on('click', '[data-section-goto]', function (event) {
    event.preventDefault();
    var componentIndex = $(this).attr("data-section-goto");

    $('html, body').animate({
      scrollTop: $('[data-section-index="' + componentIndex + '"]').offset().top - 60
    }, 500);
  });


  $(document).not('.artist-menu-tabs').on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top - 60
    }, 500);
  });


  // SHOW MAP

  var mapLabel = document.querySelector("[data-map]");

  if (mapLabel !== null) {
    var mapOpen = false;
    var mapBlock = document.querySelector("#showMap");
    mapLabel.addEventListener("click", function () {
      mapOpen = !mapOpen;
      mapBlock.classList.toggle('active');

      if (mapOpen) {
        mapLabel.querySelector(".btn-text-wrap").innerHTML = "Close Map";
      } else {
        mapLabel.querySelector(".btn-text-wrap").innerHTML = "Show Map";
      }
    });
  } // ARTIST ACCORDIONS


  var artistAccordions = document.querySelectorAll(".artist-accordion:not(.shows)");

  if (artistAccordions !== null) {
    for (var i = 0; i < artistAccordions.length; i++) {
      var artistOpen = false;
      var artistAccordion = artistAccordions[i];
      var artistCollapsedArea = artistAccordion.querySelector(".artist-accordion__collapsed");
      var accordionPreview = artistAccordion.querySelector(".artist-accordion__container");

      if (artistCollapsedArea !== null) {
        slideUp(artistCollapsedArea, 500);
        var artistAccordionTrigger = artistAccordion.querySelector(".artist-accordion__more .trigger");
        artistAccordionTrigger.addEventListener("click", function () {
          artistOpen = !artistOpen;
          slideToggle(artistCollapsedArea, 500);
          artistAccordion.classList.toggle("artist-accordion--expanded");

          if (artistOpen) {
            artistAccordion.querySelector(".btn-text-wrap").innerHTML = "Show less Artists";
          } else {
            artistAccordion.querySelector(".btn-text-wrap").innerHTML = "View all Artists";
          }
        });
      }
    }
  } // ARTWORK ACCORDIONS


  var artworkAccordions = document.querySelectorAll(".artwork-accordion");

  if (artworkAccordions !== null) {
    for (var i = 0; i < artworkAccordions.length; i++) {
      var artworkOpen = false;
      var artworkAccordion = artworkAccordions[i];
      var artworkCollapsedArea = artworkAccordion.querySelector(".artwork-accordion__collapsed");

      if (artworkCollapsedArea !== null) {
        slideUp(artworkCollapsedArea, 500);
        var artworkAccordionTrigger = artworkAccordion.querySelector(".artwork-accordion__more .trigger");
        artworkAccordionTrigger.addEventListener("click", function () {
          artworkOpen = !artworkOpen;
          slideToggle(artworkCollapsedArea, 500);

          if (artworkOpen) {
            artworkAccordion.querySelector(".btn-text-wrap").innerHTML = "Show less Artworks";
          } else {
            artworkAccordion.querySelector(".btn-text-wrap").innerHTML = "View all Artworks";
          }
        });
      }
    }
  } // SHOW ACCORDIONS

  $('.artist-accordion.shows').each(function() {
    var showAccordion = $(this);
    var showCollpasedArea = showAccordion.find($('.artist-accordion__collapsed.shows'));
      if (showCollpasedArea !== null) {
        showCollpasedArea.slideUp(); 
      }
  });

  $('.artist-accordion__more.shows .trigger .btn-text-wrap').on('click', function(e) {

    var accordionArea = $(this).parents('.artist-accordion__more').parents('.artist-accordion.shows');

    if( !accordionArea.hasClass('artist-accordion--expanded') ) {
      $(this).attr('data-original', $(this).html());
    }

    $(this).parents('.artist-accordion__more').parents('.artist-accordion.shows').toggleClass('artist-accordion--expanded').find($('.artist-accordion__collapsed.shows')).slideToggle(500);

    if( $(this).parents('.artist-accordion__more').parents('.artist-accordion.shows').hasClass('artist-accordion--expanded')) {
      $(this).html("Show fewer Shows");
    } else {
      $(this).html($(this).attr('data-original'));
    }
    e.preventDefault();
    
  });

  // var showAccordions = document.querySelectorAll(".artist-accordion.show");

  // if (showAccordions !== null) {
  //   for (var i = 0; i < showAccordions.length; i++) {

  //     var showOpen = false;
  //     var showAccordion = showAccordions[i];

  //     showAccordion.querySelectorAll(".artist-accordion__collapsed").forEach((showCollapsedArea, index) => {

  //       if (showCollapsedArea[index] !== null) {
  //         slideUp(showCollapsedArea[index], 500);

  //         showAccordion.querySelectorAll('.artist-accordion__more.show .trigger').forEach((showAccordionTrigger, index) => {
  //           showAccordionTrigger[index].addEventListener('click', () => {
  //             console.log(showAccordionTrigger);
  //             showOpen = !showOpen;
              
  //             slideToggle(showCollapsedArea[index], 500);

  //             if (showOpen) {
  //               showAccordion.querySelector(".trigger .btn-text-wrap").innerHTML = "Show fewer Shows";
  //             } else {
  //               showAccordion.querySelector(".trigger  .btn-text-wrap").innerHTML = "View all Shows";
  //             }
  //           });
  //         });
  //       }
  //     });
  //   }
  // } // SECONDARY NAVIGATION HIGHLIGHT

  var sections = document.querySelectorAll(".section-wrap");
  window.addEventListener('scroll', function () {
    var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

    for (var i = 0; i < sections.length; i++) {
      var section = sections[i];
      var sectionTop = section.offsetTop;
      var sectionHeight = section.offsetHeight;
      var sectionBottom = sectionTop + sectionHeight;
      var sectionID = section.getAttribute('id');

      if (sectionTop <= scrollPosition) {
        var navigationLinks = document.querySelectorAll(".secondary-navigation__links a");
        var navigationLink = document.querySelector('.secondary-navigation__links a[href="#' + sectionID + '"]');

        if (navigationLink !== null) {
          for (var j = 0; j < navigationLinks.length; j++) {
            navigationLinks[j].classList.remove("active");
          }

          if (sectionBottom >= scrollPosition) {
            navigationLink.classList.add("active");
          }
        }
      }
    }
  }); // PLAYLIST BUTTON

  var playlistButton = document.querySelector(".playlist-button__wrap");

  if (playlistButton !== null) {
    var playlistEmbed = function playlistEmbed() {
      this.classList.toggle("active");

      if (toggled == false) {
        this.querySelector(".playlist-button__text").textContent = "Close Playlist";
        toggled = true;
      } else {
        this.querySelector(".playlist-button__text").textContent = "Show Playlist";
        toggled = false;
      }

      console.log(toggled);
    };

    var toggled = false;
    playlistButton.addEventListener("click", playlistEmbed);
  } // SHOWS CAROUSEL SLIDE


  var showCarousels = document.querySelectorAll(".show-carousel__carousel");

  if (showCarousels !== null) {
    for (var i = 0; i < showCarousels.length; i++) {
      $(showCarousels[i]).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
      });
    }
  }
  /* Slick needs no get Reinitialized on window Resize after it was destroyed */


  $(window).on('load resize orientationchange', function () {
    $('.column-blocks--03 .row').each(function () {
      var $carousel = $(this);
      /* Initializes a slick carousel only on mobile screens */
      // slick on mobile

      if ($(window).width() > 991) {
        if ($carousel.hasClass('slick-initialized')) {
          $carousel.slick('unslick');
        }
      } else {
        if (!$carousel.hasClass('slick-initialized')) {
          $carousel.slick({
            dots: true,
            arrows: false,
            infinite: true,
            centerMode: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1
          });
        }
      }
    });
  }); // function columnSlick() {
  //     if (window.innerWidth < 992) {
  //         var columnCarousels = document.querySelectorAll(".column-blocks--03 .row");
  //         if (columnCarousels !== null) {
  //             for (var i = 0; i < columnCarousels.length; i++) {
  //                 $(columnCarousels[i]).slick({
  //                     dots: true,
  //                     arrows: false,
  //                     infinite: true,
  //                     centerMode: true,
  //                     speed: 300,
  //                     slidesToShow: 1,
  //                     slidesToScroll: 1,
  //                 });
  //             }
  //         }
  //     }
  // }
  // columnSlick();

  if (document.body.classList.contains('shows-template-page-shows-new')) {
    ScrollTrigger.matchMedia({
      "(min-width: 991px)": function minWidth991px() {
        ScrollTrigger.create({
          trigger: ".comp-show-details",
          start: "top 60px",
          // endTrigger: "html",
          // end: "bottom bottom",
          pin: true,
          pinSpacing: false,
          scrub: 1
        });
        var tweenOverlay = gsap.to(".comp-show-details .text-image-block__overlay", {
          css: {
            opacity: 0.8
          }
        });
        ScrollTrigger.create({
          trigger: ".comp-show-details",
          start: "top 100px",
          scrub: true,
          // end: "bottom bottom",
          animation: tweenOverlay
        });
      }
    });
  }

  var fullWidthHeroWrap = document.querySelectorAll(".comp-full-width-hero");

  if (fullWidthHeroWrap !== null) {
    for (var i = 0; i < fullWidthHeroWrap.length; i++) {
      ScrollTrigger.matchMedia({
        "(min-width: 991px)": function minWidth991px() {
          ScrollTrigger.create({
            trigger: fullWidthHeroWrap[i],
            start: "top 60px",
            // end: "top bottom",
            pin: true,
            pinSpacing: false,
            scrub: 1
          });
        },
        "(max-width: 990px)": function maxWidth990px() {
          ScrollTrigger.create({
            trigger: fullWidthHeroWrap[i],
            start: "top-=55 top",
            pin: true,
            pinSpacing: false,
            scrub: 1
          });
        }
      });
    }
  }

  var fullWidthHeroComponents = document.querySelectorAll(".full-width-hero");

  if (fullWidthHeroComponents !== null) {
    for (var i = 0; i < fullWidthHeroComponents.length; i++) {
      var fullWidthContent = fullWidthHeroComponents[i].querySelector(".full-width-hero__content");
      var fullWidthOverlay = fullWidthHeroComponents[i].querySelector(".full-width-hero__overlay");
      var tween = gsap.to(fullWidthContent, {
        css: {
          scaleX: 0.8,
          scaleY: 0.8,
          opacity: 0.2
        }
      });
      ScrollTrigger.create({
        trigger: fullWidthHeroComponents[i],
        start: "top 60px",
        scrub: true,
        // end: "bottom bottom",
        animation: tween
      });
      var tween02 = gsap.to(fullWidthOverlay, {
        css: {
          opacity: 0.8
        }
      });
      ScrollTrigger.create({
        trigger: fullWidthHeroComponents[i],
        start: "top 60px",
        scrub: true,
        // end: "bottom bottom",
        animation: tween02
      });
    }

    ;
  }

  ScrollTrigger.matchMedia({
    "(min-width: 991px)": function minWidth991px() {
      var tween04 = gsap.to(".parallax-quote__overlay", {
        css: {
          y: -400
        }
      });
      ScrollTrigger.create({
        trigger: ".parallax-quote",
        start: "top bottom",
        scrub: true,
        // end: "bottom bottom-=200",
        animation: tween04
      }); // ScrollTrigger.create({
      //     trigger: ".parallax-quote__background-image",
      //     start: "top 100%",
      //     pin: true,
      //     pinSpacing: false,
      //     scrub: 1,
      //     markers: true,
      // });
      // var parallaxBlock = gsap.to(".parallax-quote__background-image", {css:{y:-400}});
      // ScrollTrigger.create({
      //     trigger: ".parallax-quote",
      //     start: "top bottom",
      //     scrub: true,
      //     // end: "bottom bottom-=1000",
      //     animation: parallaxBlock,
      //     // markers: true,
      // });
    },
    "(max-width: 990px)": function maxWidth990px() {
      var tween04 = gsap.to(".parallax-quote__overlay", {
        css: {
          y: -100
        }
      });
      ScrollTrigger.create({
        trigger: ".parallax-quote",
        start: "top bottom",
        scrub: true,
        // end: "bottom bottom-=200",
        animation: tween04
      });
      var parallaxBlock = gsap.to(".parallax-quote__background-image", {
        css: {
          y: -100
        }
      });
      ScrollTrigger.create({
        trigger: ".parallax-quote",
        start: "top bottom",
        scrub: true,
        // end: "bottom bottom-=1000",
        animation: parallaxBlock // markers: true,

      });
    }
  });
  ScrollTrigger.matchMedia({
    "(min-width: 991px)": function minWidth991px() {
      gsap.utils.toArray(".text-image-block").forEach(function (section) {
        var sectionImage = section.querySelector(".text-image-block__image");
        var imageAnim = gsap.to(sectionImage, {
          css: {
            y: -150
          }
        });
        ScrollTrigger.create({
          trigger: section,
          start: "top bottom",
          scrub: true,
          animation: imageAnim
        });
      });
    },
    "(max-width: 990px)": function maxWidth990px() {
      gsap.utils.toArray(".text-image-block").forEach(function (section) {
        var sectionImage = section.querySelector(".text-image-block__image");
        var imageAnim = gsap.to(sectionImage, {
          css: {
            y: -100
          }
        });
        ScrollTrigger.create({
          trigger: section,
          start: "top bottom",
          scrub: true,
          animation: imageAnim
        });
      });
    }
  });
  ScrollTrigger.matchMedia({
    "(min-width: 991px)": function minWidth991px() {
      ScrollTrigger.create({
        trigger: ".text-image-block--list",
        start: "top 150px",
        end: "bottom bottom",
        pin: ".text-image-block__sticky",
        pinSpacing: false,
        scrub: 1
      });
    }
  });
  gsap.utils.toArray(".artist-accordion__block").forEach(function (element, i) {
    gsap.set(element, {
      opacity: 0,
      y: 200,
      overwrite: true,
      force3D: true
    });
    gsap.to(element, {
      y: 0,
      opacity: 1,
      force3D: true,
      overwrite: true,
      scrollTrigger: {
        trigger: element,
        start: "top 100%"
      }
    });
  });
  var titleOverlays = document.querySelectorAll(".js-overlay-enabled");

  if (titleOverlays !== null) {
    for (var i = 0; i < titleOverlays.length; i++) {
      var overlay = titleOverlays[i].querySelector(".title-overlay");
      ScrollTrigger.create({
        trigger: titleOverlays[i],
        start: "top 50%",
        once: true,
        toggleClass: {
          targets: overlay,
          className: "active"
        }
      });
    }
  }

  ScrollTrigger.matchMedia({
    "(min-width: 991px)": function minWidth991px() {// ScrollTrigger.create({
      //     trigger: ".full-bleed-artwork",
      //     start: "top bottom",
      //     // pin: true,
      //     // pinSpacing: true,
      //     scrub: 1,
      //     markers: true,
      //     toggleClass: {targets: ".full-bleed-artwork", className: "active"},
      // });
      // var fullBleedImage = document.querySelector(".full-bleed-artwork__background-image");
      // fullBleedImage.style.backgroundPosition = `50% ${-innerHeight / 5}px`;
      // gsap.to(fullBleedImage, {
      //     backgroundPosition: `50% ${innerHeight / 5}px`,
      //     ease: "none",
      //     scrollTrigger: {
      //       trigger: ".full-bleed-artwork",
      //       scrub: true
      //     }
      // });
      // var tween07 = gsap.to(".full-bleed-artwork__info", {css:{y:-600}});
      // ScrollTrigger.create({
      //     trigger: ".full-bleed-artwork",
      //     start: "top top",
      //     scrub: true,
      //     end: "bottom bottom",
      //     animation: tween07,
      // });
      // ScrollTrigger.refresh();
    }
  }); // ScrollTrigger.batch(".artist-accordion__block", {
  //     onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, overwrite: true}),
  //     onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 200, overwrite: true})
  // });
  // ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".artist-accordion__block", {y: 0}));
  // BATCH LOAD BLOCKS

  var fadeInBlocks = document.querySelector(".st-fade-in");

  if (fadeInBlocks !== null) {
    gsap.set(".st-fade-in", {
      opacity: 0,
      y: 50,
      force3D: true
    });
    ScrollTrigger.batch(".st-fade-in", {
      start: "top 80%",
      onEnter: function onEnter(batch) {
        return gsap.to(batch, {
          opacity: 1,
          y: 0,
          overwrite: true,
          force3D: true
        });
      }
    }); // ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".st-fade-in", {opacity: 0, y:50, force3D: true}));
  } // PROGRESS BAR ANIMATION


  gsap.to('progress', {
    value: 100,
    ease: 'none',
    scrollTrigger: {
      scrub: 0.3
    }
  }); // MOBILE READMORE FUNCTIONALITY

  function readMoreInit() {
    new Readmore('.js-collapse-content', {
      speed: 500,
      collapsedHeight: 120,
      lessLink: '<a href="#" class="js-read-less">read less</a>',
      moreLink: '<a href="#" class="js-read-more">...more</a>'
    });
  }

  readMoreInit(); // window.addEventListener("resize", readMoreInit);

  var secondaryNavigation = document.querySelector(".secondary-navigation");

  if (secondaryNavigation !== null) {
    ScrollTrigger.matchMedia({
      "(min-width: 991px)": function minWidth991px() {
        var showAnim = gsap.from('.site-header', {
          y: -122,
          paused: true,
          duration: 0.3
        }).progress(1);
        ScrollTrigger.create({
          start: "top top",
          end: 99999,
          onUpdate: function onUpdate(self) {
            self.direction === -1 ? showAnim.play() : showAnim.reverse();
          }
        });
      }
    });
  } // SHOWS LANDING


  ScrollTrigger.matchMedia({
    "(min-width: 991px)": function minWidth991px() {
      ScrollTrigger.create({
        trigger: ".show-list",
        start: "top 150px",
        end: "bottom bottom",
        pin: ".show-list__image-container",
        pinSpacing: false,
        scrub: 1
      });
    }
  }); // BATCH LOAD BLOCKS

  var showListItems = document.querySelector(".show-list__item");

  if (showListItems !== null) {
    gsap.set(".show-list__item", {
      x: -40,
      force3D: true
    });
    ScrollTrigger.batch(".show-list__item", {
      start: "top 80%",
      onEnter: function onEnter(batch) {
        return gsap.to(batch, {
          opacity: 1,
          x: 0,
          force3D: true,
          overwrite: true
        });
      }
    }); // ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".show-list__item", {x: -40}));
  } // SHOW LIST ITEM HOVER


  var showListItemLinks = document.querySelectorAll(".show-list__item");
  var showListImages = document.querySelectorAll(".show-list__image-block");

  if (showListItemLinks != null) {
    for (var i = 0; i < showListItemLinks.length; i++) {
      var showListItem = showListItemLinks[i];
      showListItem.addEventListener("mouseenter", function () {
        var itemIndex = this.getAttribute('data-show-item-index');
        var itemImage = document.querySelector("[data-show-image-index='" + itemIndex + "']");

        for (var j = 0; j < showListImages.length; j++) {
          showListImages[j].classList.remove("active");
        }

        itemImage.classList.add("active");
      });
    }
  }

  document.addEventListener('lazyloaded', function (e) {
    ScrollTrigger.refresh();
  });
});